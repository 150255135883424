<template>
  <q-form ref="editForm">
    <c-card title="LBLDETAIL" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="map"
            :mappingType="mappingType"
            label="LBLSAVEALL" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :required="true"
            :editable="editable"
            label="배치도면"
            name="mapName"
            v-model="map.mapName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-fire-fighting-location
            :required="true"
            :editable="editable"
            type="edit"
            name="sopFireFightingLocationId"
            label="장소"
            v-model="map.sopFireFightingLocationId"
          ></c-fire-fighting-location>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            :required="true"
            :editable="editable"
            type="edit" 
            name="plantCd" 
            v-model="map.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            type="number"
            label="LBLSORTORDER"
            name="sortOrder"
            v-model="map.sortOrder">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-radio 
            :editable="editable"
            :comboItems="useFlagItems"
            label="LBLUSEFLAG"
            name="useFlag" 
            v-model="map.useFlag">
          </c-radio>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-upload 
          style="margin-top:0 !important"
          :attachInfo="attachInfo"
          :editable="editable"
          :uploaderShow="uploaderShow"
          @files="setMap">
        </c-upload>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-card title="소방설비 배치도면" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
          <template slot="card-detail">
            <div class="col-12">
              <div id="parent" ref="parent">
                <div v-if="map.fireFights&&map.fireFights.length>0&&setComplete"
                  :style="{'height': imgHeight}">
                  <!-- <VueDraggableResizable
                    v-for="(mp, idx) in map.fireFights"
                    :key="idx"
                    ref="markImage"
                    class="my-class"
                    :parent="true"
                    :draggable="editable"
                    :resizable="editable"
                    class-name-dragging="my-dragging-class"
                    class-name-active="my-active-class"
                    :x="mp.x"
                    :y="mp.y"
                    :w="mp.w" :h="mp.h"
                    :grid="[5, 5]"
                    :minHeight="20" :minWidth="20"
                    @dragging="(x, y) => onDrag(mp, x, y)"
                    @resizing="(x, y, w, h) => onResize(mp, x, y, w, h)"
                  >
                    <q-tooltip anchor="top middle" self="center middle">{{mp.processName}}</q-tooltip>
                  </VueDraggableResizable> -->
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
// import VueDraggableResizable from 'vue-draggable-resizable'
// import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingLayoutDetail',
  // components: { VueDraggableResizable },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          sopFireFightingMapId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      map: {
        sopFireFightingMapId: '',  // 지도 일련번호
        plantCd: '',  // 사업장 코드
        mapName: '',  // 지도명
        sopFireFightingLocationId: '',
        ratio: null,
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sysAttachFileId: '',  // 수정자 ID
        contentType: '',  // 수정자 ID
        // fireFights: [], // 공정s
        sortOrder: 0,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'FIRE_FIGHTING_MAP',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      uploaderShow: true,
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      popupOptions: {
        isFull: false,
        width: '60%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 200);
      if (height < 500) {
        height = 500;
      }
      this.imgHeight = String(height) + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingMap.get.url
      this.saveUrl = transactionConfig.fft.fireFightingMap.insert.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopFireFightingMapId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingMapId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.map, _result.data);
          this.$set(this.attachInfo, 'taskKey', this.map.sopFireFightingMapId)
        },);
      }
    },
    setMap(files) {
      if (files && files.length > 0) {
        let data = files[0];
        this.$comm.previewImage({
          sysAttachFileId: data.sysAttachFileId,
          contentType: data.contentType,
        }).then(_result => {
          this.mapSrc = _result;
          this.setCanvas(5)
          this.uploaderShow = false;
        });
      } else {
        this.mapSrc = require('@/assets/images/no-image.png');
        this.setCanvas(5)
        this.uploaderShow = true;
      }
    },
    setCanvas(size) {
      let image = new Image();
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.map.ratio = image.width / image.height
        let _width = thisVue.map.ratio * 575

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px) 0% 0% / ' 
          + size + 'px ' + size + 'px, linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px) 0% 0% / ' + size + 'px ' + size + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          + 'height:575px;width:' + _width + ';';

        setTimeout(() => {
          thisVue.setComplete = true;
        }, 300);
      };
    },
    /* eslint-disable no-unused-vars */
    onDrag(item, x, y) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    onResize(item, x, y, w, h) {
      this.$set(item, 'x', x)
      this.$set(item, 'y', y)
      this.$set(item, 'w', w)
      this.$set(item, 'h', h)
      if (item.editFlag !== 'C') {
        this.$set(item, 'editFlag', 'U')
        this.$set(item, 'chgUserId', this.$store.getters.user.userId)
      }
    },
    save() {
      if (this.popupParam.sopFireFightingMapId) {
        this.saveUrl = transactionConfig.fft.fireFightingMap.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingMap.insert.url
        this.mappingType = 'POST'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.map.regUserId = this.$store.getters.user.userId
              this.map.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      if (!this.popupParam.sopFireFightingMapId) {
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.popupParam.sopFireFightingMapId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
<style>
.my-class {
  /* background-color: #9E9E9E; */
  border: 2px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: rgb(255, 87, 34, 0.7);
  border: 2px dashed #FF5722;
}
.my-active-class{
  background-color: rgb(255, 87, 34, 0.25);
  border: 2px dashed #FF5722;
}
</style>