var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLDETAIL" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.map,
                      mappingType: _vm.mappingType,
                      label: "LBLSAVEALL",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.save,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    label: "배치도면",
                    name: "mapName",
                  },
                  model: {
                    value: _vm.map.mapName,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "mapName", $$v)
                    },
                    expression: "map.mapName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-fire-fighting-location", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    type: "edit",
                    name: "sopFireFightingLocationId",
                    label: "장소",
                  },
                  model: {
                    value: _vm.map.sopFireFightingLocationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "sopFireFightingLocationId", $$v)
                    },
                    expression: "map.sopFireFightingLocationId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.map.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "plantCd", $$v)
                    },
                    expression: "map.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    type: "number",
                    label: "LBLSORTORDER",
                    name: "sortOrder",
                  },
                  model: {
                    value: _vm.map.sortOrder,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "sortOrder", $$v)
                    },
                    expression: "map.sortOrder",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-radio", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.useFlagItems,
                    label: "LBLUSEFLAG",
                    name: "useFlag",
                  },
                  model: {
                    value: _vm.map.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.map, "useFlag", $$v)
                    },
                    expression: "map.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
          [
            _c("c-upload", {
              staticStyle: { "margin-top": "0 !important" },
              attrs: {
                attachInfo: _vm.attachInfo,
                editable: _vm.editable,
                uploaderShow: _vm.uploaderShow,
              },
              on: { files: _vm.setMap },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: "소방설비 배치도면",
                  noMarginPadding: true,
                  height: _vm.imgHeight,
                },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { ref: "parent", attrs: { id: "parent" } }, [
                      _vm.map.fireFights &&
                      _vm.map.fireFights.length > 0 &&
                      _vm.setComplete
                        ? _c("div", { style: { height: _vm.imgHeight } })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }